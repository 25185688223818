import { makeAutoObservable, observable } from "mobx";
import { RootStore } from ".";

class ErrorsStore {
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
  }

  @observable
  errorsList: [] = [];

  // @action
  // getErrors = () => {
  //
  // };
}

export default ErrorsStore;
