import AuthStore from "./AuthStore";
import ErrorsStore from "./ErrorsStore";
import PrintersStore from "./PrintersStore";
import RFIDTicketsStore from "./RFIDTicketsStore";
import NavBarStore from "./NavBarStore";
export class RootStore {
  AuthStore: AuthStore;
  ErrorsStore: ErrorsStore;
  PrintersStore: PrintersStore;
  RFIDTicketsStore: RFIDTicketsStore;
  NavBarStore: NavBarStore;
  constructor() {
    this.AuthStore = new AuthStore(this);
    this.ErrorsStore = new ErrorsStore(this);
    this.PrintersStore = new PrintersStore(this);
    this.RFIDTicketsStore = new RFIDTicketsStore(this);
    this.NavBarStore = new NavBarStore(this);
  }
}

export default new RootStore();