import dayjs from "dayjs";
import apiClient from "../api/apiClient";
import { ERRORS_API } from "../api/consts";
import { ErrorFilter } from "../interfaces/ErrorFilter";
import { ISort } from "../interfaces/Sort";
import { removeNulls } from "../utils/helpers";



function _formatErrorFilter(sortData: any) {
  const { fromDate, toDate, pageSize } = sortData;

  const _sort: ErrorFilter = {
    fromDate: fromDate ? dayjs(fromDate).format("YYYY-MM-DD") : undefined,
    toDate: toDate ? dayjs(toDate).format("YYYY-MM-DD") : undefined,
    page: sortData.page,
    // order: [
    //   [sortData.field, sortData.sort ? sortData.sort.toUpperCase() : "ASC"],
    // ],
    limit: pageSize,
    clubPrefix: sortData.clubPrefix ? sortData.clubPrefix : '',
    searchPhrase: sortData.searchPhrase
  };

  const _sortWithNoNulls = removeNulls(_sort);

  return _sortWithNoNulls;
}

export const getErrors = async (sortData: any) => {
  try {
    const res = await apiClient.post(`${ERRORS_API}/findAllErrors`, _formatErrorFilter(sortData));
		if(res?.status === undefined) return Promise.reject(res);
		if(res.status !== 200) return Promise.reject(res.statusText);
    return Promise.resolve(res.data);
  } catch (error: any) {
    return Promise.reject(error);
  }
};

export const orderBy = async ({ field, sort, page }: ISort) => {
  try {
    // const res: any = await apiClient.get(
    //   `${ERRORS_ORDERBY_API}?page=${page}&col=${field}&order=${sort.toUpperCase()}`
    // );
    // return Promise.resolve(res);
  } catch (error: any) {
    return error?.message;
  }
};
