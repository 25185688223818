import { GridRowId } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import DataTable from "../../components/table/Table";
import { getAllAreaCodes, reprintRFIDTickets, getAllPriceBandsCodes, getAllClubsCodes, getAllEventsByClub, getAllStadiumsCodes } from "../../fetchers/RFIDTickets";
import { columns } from "./consts";
// import ResponseComponent from "./ResponseComponent";
import rootStores from "../../stores";
import { ISort } from "../../interfaces/Sort";
import RightDialog from "../../components/RightDialog/RightDialog"
import { toJS } from "mobx";
import { observer } from "mobx-react";
const RFIDTicketsStore = rootStores.RFIDTicketsStore;

type Props = {
  isNotPrinted: boolean;
}

export enum DialogState {
	RowsSelected = "RowsSelected",
	Loader = "ShowLoader",
	AfterPrint = "AfterPrint"  
} 

const fetchEventsByClub = () => {
	getAllEventsByClub(RFIDTicketsStore.club?.clubPrefix).then(events => {
		RFIDTicketsStore.setEvents(events);
	}).catch(console.error);
}

const fetchFiltersData = () => {

	getAllAreaCodes().then(areas => {
		RFIDTicketsStore.setAreas(areas);
		RFIDTicketsStore.setAllAreasCodes(areas);
	}).catch(console.error);
	
  getAllStadiumsCodes().then(stadiums => {
		RFIDTicketsStore.setStadiums(stadiums);
    RFIDTicketsStore.setAllStadiumsCodes(stadiums);
  }).catch(console.error);
  
  getAllPriceBandsCodes().then(priceBands => {
		RFIDTicketsStore.setPriceBands(priceBands);
	}).catch(console.error);
	
  fetchEventsByClub();

	getAllClubsCodes().then(clubs => {
		RFIDTicketsStore.setClubs(clubs);
    RFIDTicketsStore.setAllClubsCodes(clubs);
    RFIDTicketsStore.setFilterField('clubPrefix', RFIDTicketsStore.club ? RFIDTicketsStore.club.clubPrefix : clubs[0]?.clubPrefix);
    RFIDTicketsStore.setClub(RFIDTicketsStore.club ? RFIDTicketsStore.club : clubs[0]);
	}).catch(console.error);
}

const RFIDTicketsComponent: React.FC<Props> = ({ isNotPrinted }: Props) => {

	const [selectedRowsData, setSelectedRowsData] = useState<GridRowId[]>([]);
  const [isRightDialogOpen, setIsRightDialogOpen] = useState<boolean>(false);
  const [level, setLevel] = useState<DialogState>(DialogState.RowsSelected); //0 - show selected rows, 1 - show loader, 2 - show after print card
  const [afterPrintData, setAfterPrintData] = useState<[]>([]); 
  const [isSelectTen, setIsSelectTen] = useState<boolean>(false);
  // PAGINATION DATA //
  const [pageSize, setPageSize] = React.useState<number>(100);
	
  const { setIsTableUpdated, setFilterField, setRFIDNotPrintedTickets } = RFIDTicketsStore
	const filter : ISort = toJS(RFIDTicketsStore.filter);
	const rows = toJS(RFIDTicketsStore.rfidNotPrintedTickets);
	const isTableLoading = toJS(RFIDTicketsStore.isLoadingRFIDNotPrintedTickets);
	const totalRecordsInDB = toJS(RFIDTicketsStore.totalRFIDNotPrintedTicketsInDB);

	const selectLimit = 20;

	useEffect(() => {
		fetchFiltersData();
    RFIDTicketsStore.setFilterField("searchPhrase", "");
		return () => {
			console.log("unmount");
			RFIDTicketsStore.clearFilter();
		};
	}, []);

	useEffect(() => {
		fetchEventsByClub();
	}, [RFIDTicketsStore.club]);
	
  useEffect(() => {
    RFIDTicketsStore.fetchRFIDNotPrintedTickets(pageSize, isNotPrinted);
  }, [filter.page, filter.clubPrefix, pageSize, isNotPrinted, level])
	
	useEffect(() => {
		if(selectedRowsData.length > 0) setIsRightDialogOpen(true);
	}, [selectedRowsData])

  const handleSortModelChange = (field : string, sort : string) => {
		RFIDTicketsStore.setFilterField("field", field);
		RFIDTicketsStore.setFilterField("sort", sort);
    RFIDTicketsStore.fetchRFIDNotPrintedTickets(pageSize, isNotPrinted);
  };
	
  const handleFilter = () => {
    return RFIDTicketsStore.fetchRFIDNotPrintedTickets(pageSize, isNotPrinted);
  };

  const closeDialog = () => {
    setIsRightDialogOpen(false);
  };

  const printRFIDTickets = async () => {
    const ticketsToPrint = rows.filter((row) => selectedRowsData.includes(row.id))
    const club = toJS(RFIDTicketsStore.club)

    if (ticketsToPrint.length) {
			setLevel(DialogState.Loader);

      const { data } = await reprintRFIDTickets(ticketsToPrint, club);

      const updatedRows = rows.map((rowItem) => {
        const currResMatchItem = data.data.find((item: any) => rowItem.id === item.memberData.id);
        
        if (currResMatchItem) {
          rowItem.errorCode = currResMatchItem.response.errorCode;
          rowItem.errorDesc = currResMatchItem.response.errorDesc;
          rowItem.errorNumber = currResMatchItem.response.errorNumber;
        } else {
          rowItem.errorCode = '';
          rowItem.errorDesc = ''
          rowItem.errorMessage = '';
        }
        
        return rowItem;
      });
      
      const filteredRows = updatedRows.filter(item => item.errorCode !== 'S')// filter each item that printet successfully
      if(updatedRows.length !== filteredRows.length) setIsTableUpdated(true) // leave view level 2 (afterPrint) if there is diffrence
      
			
      // If isNotPrinted is false so in printsArchive page keep same array else filter.
      if (isNotPrinted) {
        setRFIDNotPrintedTickets(filteredRows)
      } else {
				setRFIDNotPrintedTickets(updatedRows)
      }

      setAfterPrintData(data.data);

			setLevel(DialogState.AfterPrint);
      
    } else {
      alert("You must Choose");
    }
  }

	const selectFirstTenRows = () => {
    if (isSelectTen) {
      setSelectedRowsData([])
      setIsSelectTen(false);
      closeDialog()
    } else {
			let countToSelect = selectLimit - selectedRowsData.length;
			const rowsToAdd : GridRowId[] = [];

			rows.forEach((row : any) => {
				if(countToSelect <= 0) return;

				if(selectedRowsData.includes(row.id) === false) {
					rowsToAdd.push(row.id);
					countToSelect--;
				}
			})

      setSelectedRowsData([...selectedRowsData, ...rowsToAdd]);
      setIsSelectTen(true);
      setLevel(DialogState.RowsSelected);
    }
  };

	const onClearItems = () => {
			setSelectedRowsData([]);
      setAfterPrintData([]);
			setIsSelectTen(false);
			closeDialog();
  };

	const onRowSelect = (id : GridRowId) => {

		if(level !== DialogState.Loader) {
			setLevel(DialogState.RowsSelected);
		}

		if(selectedRowsData.length >= selectLimit) {
			alert(`Not possible to select more then ${selectLimit}`);
			return;
		}

		if(level === DialogState.AfterPrint) {
			setSelectedRowsData([id])
		} else {
			setSelectedRowsData([...selectedRowsData, id]);
		}

		//setIsRightDialogOpen(true);
	}
	
	const onRowUnselected = (id : GridRowId) => {
		const newRows = selectedRowsData.filter(row => row !== id);
		setSelectedRowsData(newRows);
		
		if(isSelectTen) {
			setIsSelectTen(false);
		}

		if(newRows.length === 0 ) setIsRightDialogOpen(false);

	}

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse"}}>
			<div style={{ margin: "30px 50px 0 50px", display: "flex", flexDirection: "column", flexGrow: 1}}>
        
				<Header
          handleFilter={handleFilter}
          setIsRightDialogOpen={setIsRightDialogOpen}
          isRightDialogOpen={isRightDialogOpen}
					onSelectTen={selectFirstTenRows}
					selectedRows={selectedRowsData}
          filterFields={["orderDate", "area", "priceBand", "event"]}
					/>

        <DataTable
          rows={rows}
          columns={columns}
          handleSortModelChange={handleSortModelChange}
          isLoading={isTableLoading}
          withCheckbox={true}
          withAllCheckbox={false}
          // PAGINATION DATA // 
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={filter.page}
          setPage={(val) => setFilterField("page", val)}
          totalRecordsInDB={totalRecordsInDB}

					selectedRowsData={selectedRowsData.filter(id => rows.some(row => row.id === id)) }
					onRowSelected={onRowSelect}
					onRowUnselected={onRowUnselected}
					/>
			</div>

				{isRightDialogOpen &&
        <RightDialog
          removeSelectedRowsById={onRowUnselected}
          selectedRowsData={selectedRowsData.map(id => ({id: id as number, orderNumber: rows.find(row => row.id === id)?.orderNumber as number}) )}
          setIsRightDialogOpen={setIsRightDialogOpen}
          printRFIDTickets={printRFIDTickets}
          level={level}
        	setLevel={setLevel}
        	afterPrintData={afterPrintData}
        	setAfterPrintData={setAfterPrintData}
					onClearItems={onClearItems}
        />
      }
    </div>
  );
};

export default observer(RFIDTicketsComponent);
