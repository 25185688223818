import axios from "axios";
import { API_URL } from "./consts";

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: { Accept: "application/json" },
  // timeout: 30000,
});

export default apiClient;
