import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import DataTable from "../../components/table/Table";
import { getErrors } from "../../fetchers/errors";
import { columns } from "./consts";
import "./Errors.css";
import rootStores from "../../stores";
import { observer } from "mobx-react";
import { toJS } from "mobx";

const RFIDTicketsStore = rootStores.RFIDTicketsStore;

const Errors = () => {
  const [pageSize, setPageSize] = React.useState<number>(100);
  const [page, setPage] = useState<number>(1);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(true);

  const [rows, setRows] = useState([]);
  const [totalRecordsInDB, setTotalRecordsInDB] = useState<number>(0);

  useEffect(() => {
    RFIDTicketsStore.setFilterField("searchPhrase", "");
    return () => {
      RFIDTicketsStore.setFilterField("searchPhrase", "");
    };
  }, []);

  useEffect(() => {
    fetchTableData();
  }, [page, pageSize]);

  const fetchTableData = async () => {
    const sortData = toJS(RFIDTicketsStore.filter);

    setIsTableLoading(true);

    getErrors(sortData)
      .then((errors) => {
        setTotalRecordsInDB(errors?.data?.rows);
        setRows(errors?.data?.result);
      })
      .catch((err) => {
        console.log("\x1b[33m  file: errors  error:", err);
        setTotalRecordsInDB(0);
        setRows([]);
      })
      .finally(() => {
        setIsTableLoading(false);
      });
  };

  return (
    <div
      style={{ width: "100%", display: "flex", flexDirection: "row-reverse" }}
    >
      <div
        style={{
          margin: "30px 50px 0 50px",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}
      >
        <Header
          handleFilter={() => {
            console.log("handle filter");
            fetchTableData();
          }}
          isRightDialogOpen={true}
          filterFields={["orderDate", "area", "priceBand", "event"]}
        />

        <DataTable
          rows={rows}
          columns={columns}
          isLoading={isTableLoading}
          withAllCheckbox={false}
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalRecordsInDB={totalRecordsInDB}
        />
      </div>
    </div>
  );
};

export default observer(Errors);
