import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, redirect, Navigate, Outlet } from "react-router-dom";
import { CircularProgress, Grid, ThemeProvider, createTheme } from "@mui/material";
import { observer } from "mobx-react";
import Errors from "./pages/errors/Errors";
import NavBar from "./components/nav-bar/NavBar";
import LoginComponent from "./pages/login/LoginComponent";
import rootStores from "./stores";
import { getAuthData } from "./fetchers/authentication";
import Api from "./api/apiClient";
import RFIDTicketsComponent from "./pages/RFIDTickets/RFIDTicketsComponent";
import NewClubComponent from "./pages/newClub/NewClubComponent";
import TicketsCompareComponent from "./pages/ticketsCompare/TicketsCompareComponent";
import RenewSeasonComponent from "./pages/renewSeason/RenewSeasonComponent";
const authStore = rootStores.AuthStore;

Api.interceptors.response.use(
  (response) => response,
  (error) => {
    const statusCode = error?.response?.data?.statusCode;
    if (statusCode === 403 || statusCode === 401) {
      authStore.setAuthenticated(false);
    }
    return error?.message;
  }
);

const theme = createTheme({ typography: { fontFamily : 'Rubik'}, palette :  {primary : {main : "#5C6BC0"}} });

const App: React.FC = observer(() => {
  const { authenticated, setAuthenticated, setUserName, setClubPrefix } = authStore;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const res = await getAuthData();
      if (res?.status === 200) {
        setUserName(res?.data?.name);
        setClubPrefix(res?.data.clubPrefix);
        setAuthenticated(true);
      } else setAuthenticated(false);
      setIsLoading(false);
    })();
  }, [setAuthenticated, setUserName]);

	const RedirectLogin = () => {
		console.log("navigating to login")
		return <Navigate to="/login"/>
	}

	const AuthGuard = () => {
		if(!authenticated) return <RedirectLogin/>;
		else return <></>
	}

	const Layout = (): JSX.Element => {
    return (
      <>
        <AuthGuard />
        <div className="main-container">
          <NavBar />
          <Outlet />
        </div>
      </>
    );
  };

	const LoadingSpiner = () => {
		return <Grid
								container
								spacing={0}
								direction="column"
								alignItems="center"
								justifyContent="center"
								style={{ minHeight: "50vh" }}
							>
								<CircularProgress color="secondary" />
							</Grid>
	}

  return (
    <ThemeProvider theme={theme}>
      <Router>
        {isLoading ? (
          <LoadingSpiner/>
        ) : (
          <>
            <Routes>
              <Route path="/login" element={<LoginComponent />} />

              <Route path="/" element={<Layout />}>
                <Route
                  path="/"
                  element={<RFIDTicketsComponent key={"not-printed"} isNotPrinted={true} />}
                />
                <Route
                  path="/RFIDTickets"
                  element={<RFIDTicketsComponent key={"not-printed"} isNotPrinted={true} />}
                />
                <Route
                  path="/printsArchive"
                  element={<RFIDTicketsComponent key={"archive"} isNotPrinted={false} />}
                />
                <Route path="/Errors" element={<Errors />} />
                <Route path="/newClub" element={<NewClubComponent />} />
                <Route path="/TicketsCompare" element={<TicketsCompareComponent />} />
                <Route path="/RenewSeason" element={<RenewSeasonComponent />} />
              </Route>
            </Routes>
          </>
        )}
      </Router>
    </ThemeProvider>
  );
});

export default App;
