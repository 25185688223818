import React, { useEffect, useState } from "react";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { Button, Typography } from "@mui/material";

type Props = {
  item: any;
  removeItem: any;
};
const dataToPrintHB: { [key: string]: string } = {
  printedDate: "זמן הדפסה",
  productCode: "קוד מוצר",
  printerCode: "קוד מדפסת",
  errorCode: "קוד שגיאה",
  errorNumber: "מספר שגיאה",
  errorDesc: "תיאור שגיאה"

};

function PrintCard(props: Props) {
  const { item, removeItem } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);
  return (
    <div className="print-container">
      <Button
        className="print-container-basic"
        style={{
          backgroundColor: item.isSuccess
          ? "#F3E5F5"
          : "rgba(254, 235, 238, 0.5)",
          height: isOpened ? 'auto' : "72px",
          flexDirection: "column",
        }}
      >
        <div
          className="button-print-title"
          onClick={() => setIsOpened(!isOpened)}
        >
          <div style={{ display: "flex", flexDirection: "row-reverse", flex: 1 }}>
            {item.isSuccess ? (
              <CheckCircleRoundedIcon sx={{ color: "#5C6BC0" }} />
              ) : (
              <ErrorRoundedIcon sx={{ color: "#E53935" }} />
            )}
            <Typography className="print-container-title-text">
              הזמנה מספר: {item.orderNumber}
            </Typography>
          </div>
          {/* {!item.isSuccess && ( */}
            <div>
              {isOpened
                ? <ArrowDropUpRoundedIcon sx={{ color: "#5C6BC0" }} />
                : <ArrowDropDownIcon sx={{ color: "#5C6BC0" }} />
              }
              {/* <RemoveCircleRoundedIcon
                sx={{ color: "#5C6BC0" }}
                fontSize="small"
                onClick={() => removeItem(item)}
              /> */}
            </div>
          {/* )} */}
        </div>
        {isOpened && (
          <div className="print-container-error-data">
            {Object.keys(dataToPrintHB).map((key: any) => (
              <div className="print-container-error-data-row" key={key}>

                <div className="print-container-error-data-row-title">
                  {dataToPrintHB[key]}
                </div>

                <div className="print-container-error-data-row-title desc">
                  {['errorCode', 'errorNumber', 'errorDesc'].includes(key)
                    ? item.response[key]
                    : item.memberData[key]}
                </div>

              </div>
            ))}
          </div>
        )}
      </Button>
    </div>
  );
}

export default PrintCard;
