import { GridColDef } from "@mui/x-data-grid";
import { valueFormatter } from "../../translation/en";
import { Tooltip } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import rootStores from "../../stores";

const RFIDTicketsStore = rootStores.RFIDTicketsStore;

const dateFormat = (params: any) => moment(params.value).format("DD/MM/YYYY")
export const columns: GridColDef[] = [
  { field: "RealMemberID", headerName: "RealMemberID" },
  { field: "memberID", headerName: "memberID" },
  { field: "cardNumber", headerName: "cardNumber" },
  { field: "errorCode", headerName: "errorCode" },
  {
    field: "errorDesc",
    headerName: "errorDesc",
    renderCell: (params: any) => (
      <Tooltip title={<h2>{params.value}</h2>}>
        <span className="table-cell-content">{params.value}</span>
      </Tooltip>
    ),
  },
  { field: "errorNumber", headerName: "errorNumber" },
  { field: "AreaCode", headerName: "AreaCode" },
  { field: "OldAreaCode", headerName: "OldAreaCode" },
  { field: "Row", headerName: "Row" },
  { field: "OldRow", headerName: "OldRow" },
  { field: "Seat", headerName: "Seat" },
  { field: "OldSeat", headerName: "OldSeat" },
  { field: "StandCode", headerName: "StandCode" },
  { field: "OldStandCode", headerName: "OldStandCode" },
  { field: "foreName", headerName: "foreName" },
  { field: "sureName", headerName: "sureName" },
  { field: "emailAddress", headerName: "emailAddress" },

  // { field: "priceBandCode", headerName: "סוג מנוי",
  // renderCell: (params: any) => {
  //   const priceBandName = RFIDTicketsStore.priceBands.find((priceBand: any) => priceBand.priceBandCode === params.value).name;

  //   return (
  //     <Tooltip title={<h2>{priceBandName}</h2>}>
  //       <span className="table-cell-content">{priceBandName}</span>
  //     </Tooltip>
  //   );
  // },},
];
