import { makeAutoObservable, observable } from "mobx";
import { RootStore } from ".";
class PrintersStore {
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
  }

  @observable
  printersList: [] = [];

  // @action
  // getPrinters = () => {
  //
  // };
}

export default PrintersStore;
