import { GridColDef } from "@mui/x-data-grid";
import { valueFormatter } from "../../translation/en";
import { Tooltip } from "@mui/material";
import ErrorIcon from '@mui/icons-material/Error';
import moment from 'moment'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import rootStores from "../../stores";

const RFIDTicketsStore = rootStores.RFIDTicketsStore;

const dateFormat = (params: any) => moment(params.value).format("DD/MM/YYYY")

const statusCell = (params: any) => {
  const { errorCode } = params.row
  if(!errorCode) return <></>
  
  return errorCode !== "S" ?
    <Tooltip title={params.row.errorDesc}>
      <div className="status">
        <div className="text">{'שגיאה'}</div>
        <ErrorIcon color="inherit" />
      </div>
    </Tooltip>
    : <Tooltip title={params.row.errorDesc}>
      <div className="status success">
        <div className="text">{'הודפס'}</div>
        <CheckCircleIcon color="inherit" />
      </div>
    </Tooltip>
}

const renderErrorDesc = (params: any) => {
  return <Tooltip title={params.row.errorDesc}>
        <div className="text">{params.row.errorDesc}</div>
    </Tooltip>
}

export const columns: GridColDef[] = [
  // { field: "id", headerName: "מספר" },
  { field: "orderDate", headerName: "תאריך הזמנה", valueFormatter: dateFormat },
  { field: "orderNumber", headerName: "מספר הזמנה" },
  { field: "foreName", headerName: "שם מנוי" },
  { field: "sureName", headerName: "שם משפחה" },
  { field: "cardNumber", headerName: "מספר כרטיס" },
  { field: "memberId", headerName: "מספר לקוח", valueFormatter: (params) => {return params.value.split("_")[1];}, },
  { field: "row", headerName: "שורה" },
  { field: "seat", headerName: "כסא" },
  { field: "stadiumCode", headerName: "איצטדיון", width: 150, valueFormatter: (params) => {
    return RFIDTicketsStore.stadiums.find((stadium: any) => stadium.stadiumCode == params.value).name;
  }, },
  { field: "areaCode", headerName: "איזור", valueFormatter: (params) => {
    return RFIDTicketsStore.areas.find((area: any) => area.areaCode == params.value).name;
  }, },
  { field: "barcode", headerName: "ברקוד", width: 220 },
  { field: "priceBandCode", headerName: "סוג מנוי",
  renderCell: (params: any) => {
    const priceBandName = RFIDTicketsStore.priceBands.find((priceBand: any) => priceBand.priceBandCode === params.value).name;

    return (
      <Tooltip title={<h2>{priceBandName}</h2>}>
        <span className="table-cell-content">{priceBandName}</span>
      </Tooltip>
    );
  },},
];
