import React, { useState, useEffect } from "react";
import "./RightDialog.css";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { Button, Typography } from "@mui/material";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import Loader from "../Loader/Loader";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import PrintCard from "./PrintCard";
import { DialogState } from "../../pages/RFIDTickets/RFIDTicketsComponent";

export interface IDialogRow {
	id : number;
	orderNumber: number;
}

type Props = {
  setIsRightDialogOpen?: any;
  selectedRowsData: IDialogRow[];
	removeSelectedRowsById: (id : number) => void;
  printRFIDTickets: any;
  level: DialogState;
  setLevel?: any;
  afterPrintData?: [];
  setAfterPrintData?: any;
	onClearItems?: () => void;
};

function RightDialog(props: Props) {
  const {
    setIsRightDialogOpen,
    selectedRowsData,
    removeSelectedRowsById,
    printRFIDTickets,
    level,
    setLevel,
    afterPrintData,
    setAfterPrintData,
		onClearItems
  } = props;

  const [isOpened, setIsOpened] = useState<boolean>(false);

  useEffect(() => {
    //rerender when selectedRowsData changed - for remove item
  console.log('level:', level)
  }, [selectedRowsData, level]);

  const removeItem = (item: IDialogRow) => {
    removeSelectedRowsById && removeSelectedRowsById(item.id);
  };

  const pausePrint = (item: any) => {
    console.log("pause print");
  };

  const showSelectedRows = () => {
    return (
      <div className="items-container items-container-scroll">
        {selectedRowsData.map((item: IDialogRow, index: number) => {
          return (
            <Button className="print-container-basic" key={index}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  width: "inherit",
									textAlign: "right"
                }}
              >
                {/* {level === 0 ? (
                  <CheckCircleRoundedIcon sx={{ color: "#5C6BC0" }} />
                ) : (
                  <Loader size={20} />
                )} */}
								{ level !== DialogState.RowsSelected && <Loader size={20} />}
                <Typography className="print-container-title-text">
                  הזמנה מספר: <b>{item.orderNumber}</b>
                </Typography>
              </div>

              <div className="button-print-title-icons">
                {level === DialogState.RowsSelected ? (
                  // <div >
                    <RemoveCircleRoundedIcon onClick={() => removeItem(item)}
                      sx={{ color: "#5C6BC0" }}
                      fontSize="small"
                    />
                  // </div>
                ) : (
                    <PauseCircleIcon
                      sx={{ color: "#5C6BC0" }}
                      fontSize="small"
                      onClick={() => pausePrint(item)}
                    />
                )}
              </div>
							
            </Button>
          );
        })}
      </div>
    );
  };

  const showAfterPrint = () => {
    console.log('afterPrintData:', afterPrintData)
    return (
      <div className="items-container">
        {afterPrintData && afterPrintData.map((item: any, index: number) => {
          return <PrintCard item={item} removeItem={removeItem} key={index}/>;
        })}
      </div>
    );
  };

  const showComponentByLevel = () => {
    switch (level) {
      case DialogState.RowsSelected:
        return showSelectedRows();
      case DialogState.Loader:
        return showSelectedRows();
      case DialogState.AfterPrint:
        return showAfterPrint();
      default:
        return <div>level 0</div>;
    }
  };

  const handlePrint = async () => {
   await printRFIDTickets();
  };

  const closeDialog = () => {
    setIsRightDialogOpen(false);
  };

  return (
    <div className="right-dialog-container">
      <div className="right-dialog-header-container">
        <Typography className="right-dialog-header-text">
          ניהול הדפסות ({selectedRowsData.length})
        </Typography>
        <CloseRoundedIcon
          fontSize="small"
          className="right-dialog-header-button"
          onClick={() => closeDialog()}
        />
      </div>

      <div className="items-container">

        <div className="btn-clear-container">
          <Button
            className='btn'
            endIcon={<DeleteRoundedIcon color="inherit" />}
            onClick={onClearItems}
						style={{marginLeft:"16px"}}
          >
            <span className="clear">נקה הכל</span>
          </Button>
        </div>

        {showComponentByLevel()}
      </div>

      <div className="button-print-container">
        {level === DialogState.Loader ? (
          <div className="btn-cancel-container">
            <Button
              disabled
              className={`print-action-basic puese`}
              sx={{
                "&.MuiButtonBase-root:hover": { backgroundColor: "#1a60a5" },
              }}
            >
              <Typography className="print-action-text">עצור הדפסות</Typography>
            </Button>
            <Button className={`print-action-basic stop`} disabled>
              <Typography className="print-action-text stop">
                ביטול כל ההדפסות
              </Typography>
            </Button>
          </div>
        ) : (
          <Button
            className="print-action-basic"
						
						onClick={() => handlePrint()}
            
						sx={{
              "&.MuiButtonBase-root:hover": { backgroundColor: "#1a60a5" },
            }}
            
						disabled={level === DialogState.AfterPrint}
          >
              {level === DialogState.RowsSelected && <Typography className="print-action-text">הדפסה</Typography>}
              {level === DialogState.AfterPrint && <Typography className="print-action-text">הושלם</Typography>}
          </Button>
        )}
      </div>
    </div>
  );
}

export default RightDialog;
