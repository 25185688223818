import apiClient from "../api/apiClient";
import { AUTH_API, LOGIN_USER_API, LOGOUT_USER_API } from "../api/consts";
import { ILogin } from "../interfaces/ILogin";

export type LoginData = {
  id : number;
  email: string;
  password: string;
  name : string;
  type: string;
  token: string;
  clubPrefix : string;
  createdAt: string;
  updatedAt: string;
}

/*
Login request
*/
export const loginReq = async ({ email, password }: ILogin) => {
  try {
    const res: any = await apiClient.post(LOGIN_USER_API, { email, password });
    return Promise.resolve(res);
  } catch (error: any) {
    return error?.message;
  }
};

/*
Logout request
*/
export const logoutReq = async () => {
  try {
    const res: any = await apiClient.get(LOGOUT_USER_API);
    return Promise.resolve(res);
  } catch (error: any) {
    return error?.message;
  }
};

export const getAuthData = async () => {
  try {
    const res: any = await apiClient.get(AUTH_API);
    return Promise.resolve(res);
  } catch (error: any) {
    return error?.message;
  }
};
