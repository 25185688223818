import { useState } from "react";
import "./FilterPopup.css";
import { PopupItems } from "./constFilterPopupItems";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Button, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import rootStores from "../../stores";
import { toJS } from "mobx";
import { ISort } from "../../interfaces/Sort";
import dayjs from 'dayjs';
import { observer } from "mobx-react";

const RFIDTicketsStore = rootStores.RFIDTicketsStore;

type Props = {
  open?: boolean;
  handleClose: () => void;
	onClickFilter: () => void;
	sortData: ISort;
	onFieldChanged: (key : keyof ISort, value : any) => void;
  filterFields: string[];
};

interface IPopupItems {
  label: string;
  name: string;
  component: string;
  options?: any;
}

function FilterPopup(props: Props) {
  const { handleClose, onClickFilter, sortData, onFieldChanged, filterFields} = props;
  
  // FILTER SELECTORS //
  const [stadium, setStadium] = useState({
    "stadiumCode": "SHF_STADIUM_29",
    "name": "טרנר באר שבע",
  })

  const formatOptions = (options: any) => {
    const opts = Array.from(new Set(
      sortData.clubPrefix
        ? options?.filter((opt: any) => opt.clubPrefix === sortData.clubPrefix)
        : options
    ))
    return opts
  }

  const getFormComponent = (item: IPopupItems) => {
    const options = getOptions(item.name);
    const { name } = item
    switch (item.component) {
      case filterFields.includes("orderDate") && "date":
        return (
          <div className="date-container">
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  label="תאריך התחלה"
                  format="dd/MM/yyyy"
                  value={sortData?.fromDate ? dayjs(sortData.fromDate).toDate() : null}
                  onChange={(fromDate: any) => onFieldChanged("fromDate", dayjs(fromDate).format('YYYY-MM-DD'))}
                />
              </LocalizationProvider>
            </div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  slotProps={{ textField: { size: 'small' } }}
                  label="תאריך סיום"
                  format="dd/MM/yyyy"
                  value={sortData?.toDate ? dayjs(sortData.toDate).toDate() : null}
                  // @ts-ignore DO NOT DELETE
                  minDate={dayjs(sortData.fromDate).toDate()}
                  onChange={(toDate: any) => onFieldChanged("toDate", dayjs(toDate).format('YYYY-MM-DD'))}
                />
              </LocalizationProvider>
            </div>
          </div>
        );
      case "select":
        switch (name) {
          case filterFields.includes("stadium") && "stadium":
            return (
              <Autocomplete
                size="small"
                options={options}
                getOptionLabel={(option: any) => option?.name}
                autoComplete
                // disableClearable
                // @ts-ignore
                value={stadium}
                renderInput={(params) => <TextField {...params} label={item.label} />}
                isOptionEqualToValue={(option, value) => option?.stadiumCode === value?.stadiumCode}
                onChange={(event: any, newValue: any) => {
									onFieldChanged("stadium", newValue.stadiumCode)
                  setStadium(newValue)
                }}
              />
            );
          case filterFields.includes("area") && "area":
            return (
              <Autocomplete
                size="small"
                options={formatOptions(options)}
                getOptionLabel={(option: any) => `${option.name} ${option.clubPrefix}-${option.id}`} 
                autoComplete
                value={sortData.area}
                renderInput={(params) => <TextField {...params} label={item.label} />}
                onChange={(event: any, newValue: any) => {
                  onFieldChanged("area", newValue)
                }}
              />
            );
          case filterFields.includes("priceBand") && "priceBand":
            return (
              <Autocomplete
                size="small"
                options={formatOptions(options)}
                getOptionLabel={(option: any) => option?.name + ' ' + option?.clubPrefix}
                autoComplete
                value={sortData.priceBand}
                renderInput={(params) => <TextField {...params} label={item.label} />}
                onChange={(event: any, newValue: any) => {
                  onFieldChanged("priceBand", newValue)
                }}
              />
            );
          case filterFields.includes("event") && "event":
            return (
              <Autocomplete
                size="small"
                options={formatOptions(options)}
                getOptionLabel={(option: any) => option?.eventTitle}
                autoComplete
                value={sortData.event}
                renderInput={(params) => <TextField {...params} label={item.label} />}
                onChange={(event: any, newValue: any) => {
                  onFieldChanged("event", newValue)
                }}
              />
            );
          default:
            return null;
        }
      default:
        return null;
    }
  };

  const getOptions = (name: string) => {
    switch (name) {
      case "stadium":
        // return toJS(RFIDTicketsStore.stadiums);
        return [{
          "stadiumCode": "SHF_STADIUM_29",
          "name": "טרנר באר שבע",
        }];
      case "area":
        return toJS(RFIDTicketsStore.areas);
      case "priceBand": 
        return toJS(RFIDTicketsStore.priceBands);
      case "event": 
        return toJS(RFIDTicketsStore.events);
      default:
        return [];
    }
  };

  const onClearFilter = () => {
    RFIDTicketsStore.clearFilter();
    // setArea(null)
    // setPriceBand(null)
  }

  return (
    <div className="filter-container">
      <div className="filter-title-container">
        <Typography
          className="filter-title"
          variant="h6"
          gutterBottom
          component="div"
        >
          סינונים
        </Typography>
        <div className="filter-close">
          <Button sx={{ padding: '5px', minWidth: 0 }} onClick={handleClose}>
            <CloseRoundedIcon color="action" />
          </Button>
        </div>
      </div>
      <div className="filter-items-container">
        {PopupItems.map((item, index) => {
					if(item.name==="stadium") return <div key={index}> </div>
          if(filterFields.includes(item.name)){
            return (
              <div className="filter-item" key={index}>
              <div className="filter-item-title">{item.label}</div>
              <div className="filter-item-component">
                {getFormComponent(item)}
              </div>
            </div>
            );
          }
        })}
      </div>
      <div className="filter-btns-container">
        <Button
          className='btn'
          onClick={onClickFilter}
        >
          <span className="show">הצג תוצאות</span>
        </Button>
        <Button
          className='btn'
          onClick={onClearFilter}
        >
          <span className="clean">נקה הכל</span>
        </Button>
      </div>
    </div>
  );
}

export default observer(FilterPopup);
