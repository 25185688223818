import { LoginData, loginReq, logoutReq } from "../fetchers/authentication";
import { action, makeAutoObservable, observable } from "mobx";
import { ILogin } from "../interfaces/ILogin";
import { RootStore } from ".";
class AuthStore {
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    this.authenticated = undefined;
    this.userName = "";
    this.clubPrefix = undefined;
    makeAutoObservable(this);
  }

  @observable authenticated: boolean | undefined;
  @observable userName: string;
  @observable clubPrefix : string | undefined;

  @action
  login = async ({ email, password }: ILogin) => {
    try {
      const res = await loginReq({ email, password });
      if (res?.status === 200) {

        const loginData : LoginData = res.data as LoginData; 

        this.setAuthenticated(true);
        this.setUserName(loginData.name);
        this.setClubPrefix(loginData.clubPrefix);

        return res;
      } else {
        this.setAuthenticated(false);
        return res;
      }
    } catch (error) {
      this.setAuthenticated(false);
      return Promise.reject(error);
    }
  };

  @action
  setAuthenticated = (authenticated: boolean) => {
    this.authenticated = authenticated;
  };

  @action
  setUserName = (name: string) => {
    this.userName = name;
  };

  @action
  setClubPrefix = (clubPrefix: string) => {
    this.clubPrefix = clubPrefix;
  };

  @action
  logout = async () => {
    try {
      const res = await logoutReq();
      if (res?.status === 200) {
        this.setAuthenticated(false);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

export default AuthStore;
