import Pagination from "@mui/material/Pagination";
import TablePagination from '@mui/material/TablePagination';
import "./Pagination.css";
interface Props {
  className?: string;
  page?: number;
  setPage?: (value: number) => void;
  pageSize?: number;
  setPageSize?: (value: number) => void;
  totalRecordsInDB?: number;
}

const CustomPagination = (props: Props) => {
  const {
    page = 0,
    setPage,
    pageSize = 10,
    setPageSize,
    totalRecordsInDB = 100,
  } = props

  const handleChangePage = (e: any, newPage: number) => {
    setPage && setPage(newPage);
  };

  const handleChangeOffset = (e: any, newPage: number) => {
    setPage && setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize && setPageSize(parseInt(event.target.value, 10));
    setPage && setPage(1);
  };

  return (
    <div className='pagination-container'>
      <div style={{display:"flex", alignItems: "center", justifyContent: "start"}}>
				<TablePagination
					className='table-pagination-1'
					component="div"
					rowsPerPageOptions={[10, 20, 50, 100]}
					rowsPerPage={pageSize}
					count={totalRecordsInDB}
					page={page - 1}
					onPageChange={handleChangeOffset}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage=""
				/>
			</div>
      
			<div style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
				<Pagination
					variant="outlined"
					shape="rounded"
					color="primary"
					count={Math.ceil(totalRecordsInDB / pageSize)}
					page={page}
					onChange={handleChangePage}
					sx={{justifySelf: "center"}}
					/>
			</div>

			<div style={{display:"flex", alignItems: "center", justifyContent: "end"}}>
				<TablePagination
					className='table-pagination-2'
					component="div"
					rowsPerPageOptions={[]}
					rowsPerPage={pageSize}
					count={totalRecordsInDB}
					page={page - 1}
					onPageChange={handleChangeOffset}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage=""
				/>
			</div>
    </div>
  );
};

export default CustomPagination;
