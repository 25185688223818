import React, { useState } from 'react';
import './popup.css'; // Import your CSS file for styling

interface PopupProps {
  isVisible: boolean;
  onClose: () => void;
  content: string;
}
  
const Popup: React.FC<PopupProps> = ({ isVisible, onClose, content }) => {
  return (
    <div>
    {isVisible && (
      <div className="popup">
        <div className="popup-content">

          <h2>{content}</h2>

          <button className="close-button" onClick={onClose}>
          Close
          </button>
        </div>
      </div>
    )}
    </div>
  );
};

export default Popup;