import apiClient from "../api/apiClient";
import { FORTRESS_API } from "../api/consts";

export type INewClubData = {
  ClubName: string;
  VenueShortCode: string;
  PrinterCode: string;
  clubPrefix: string;
  StadiumName: string;
  genre: string;
	rangeId: number;
  eventimURL: string;
  eventimUserName: string;
  eventimPassword: string;
  fortressURL: string;
  fortressUserName: string;
  fortressPassword: string;
  fortressApiKey: string;
  fortressAppId: string;
  fortressAgencyCode: string;
  fortressPort: number;
  dtsURL: string;
  dtsApiKey: string;
  eventTypeId: number;
  prdHomeGame: number;
  prdMemberCard: number;
  adminUserName: string;
  adminUserPassword: string;
  excelFile: any;
};

export const createNewClub = async (newClubData: INewClubData) => {
  try {
    const res: any = await apiClient.post(
      `${FORTRESS_API}/createClub`,
      newClubData
    );
    return res;
  } catch (error: any) {
    return error?.message;
  }
};
