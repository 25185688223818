import { Box, Button, Container, CssBaseline } from "@mui/material";

import { useForm } from "react-hook-form";
import "./NewClubComponent.css";
import NewClubFormRow from "./components/NewClubFormRow";
import { INewClubData, createNewClub } from "../../fetchers/newClub";
import { useState } from "react";
import Popup from "../../components/Popup/popup";

export type ClubFormField = {
  name: keyof INewClubData;
  label: string;
  type: React.InputHTMLAttributes<unknown>["type"];
  files?: any[]; // if the type is 'file' will be set to selected files
};

const eventimFields: ClubFormField[] = [
  { name: "ClubName", type: "text", label: "שם מועדון" },
  { name: "clubPrefix", type: "text", label: "קידומת מועדון" },
  { name: "VenueShortCode", type: "text", label: "Venue Short Code" },
  { name: "eventimURL", type: "text", label: "כתובת איוונטים" },
  { name: "eventimUserName", type: "text", label: "שם משתמש איוונטים" },
  { name: "eventimPassword", type: "password", label: "סיסמה איוונטים" },
  { name: "genre", type: "text", label: "genre" },
  { name: "dtsApiKey", type: "text", label: "API Key DTS" },
  { name: "StadiumName", type: "text", label: "שם אצטדיון" },
  { name: "PrinterCode", type: "text", label: "קוד מדפסת" },
  { name: "adminUserName", type: "text", label: "Admin User Name" },
  { name: "adminUserPassword", type: "text", label: "Admin User Password" },
  { name: "excelFile", type: "file", label: "Area Codes Excel" },
];

const fortressFields: ClubFormField[] = [
  { name: "fortressURL", type: "text", label: "כתובת Fortress" },
  { name: "fortressUserName", type: "text", label: "שם משתמש Fortress" },
  { name: "fortressPassword", type: "text", label: "סיסמה Fortress" },
  { name: "fortressApiKey", type: "text", label: "API Key Fortress" },
  // { name: "fortressAppId", type: "text", label: "AppID Fortress" },
  // { name: "fortressAgencyCode", type: "text", label: "קוד סוכנות Fortress" },
  // { name: "fortressPort", type: "number", label: "Fortress Port" },
  { name: "rangeId", type: "number", label: "Range ID" },
  { name: "eventTypeId", type: "number", label: "Event Type Id" },
  { name: "prdHomeGame", type: "number", label: "PRD Home Game" },
  { name: "prdMemberCard", type: "number", label: "PRD Member Card" },
];

const defaultFieldValues = {
  ClubName: "",
  VenueShortCode: "",
  PrinterCode: "",
  clubPrefix: "",
  StadiumName: "",
  genre: "",
	rangeId: "",
  eventimURL: "",
  eventimUserName: "",
  eventimPassword: "",
  fortressURL: "",
  fortressUserName: "",
  fortressPassword: "",
  fortressApiKey: "",
  fortressAppId: "",
  fortressAgencyCode: "",
  fortressPort: "",
  dtsURL: "",
  dtsApiKey: "",
  eventTypeId: "",
  prdHomeGame: "",
  prdMemberCard: "",
  adminUserName: "",
  adminUserPassword: "",
  excelFile: undefined,
};

const NewClubComponent = () => {
  const {
    handleSubmit,
    control,
    register,
    reset,
    resetField,
    formState: { errors, dirtyFields },
    getValues,
  } = useForm();
  const values = getValues();
  document.title = "eventim - מועדון חדש";
  const [excelData, setExcelData] = useState<any[]>([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const onSubmit = async (data: any) => {
    data.excelFile = excelData;
    const res = await createNewClub(data as INewClubData);
    reset(defaultFieldValues);
    setPopupContent(res.data.data);
    openPopup();
  };

  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
  };

  return (
    <Container
      component="main"
      maxWidth="xl"
      className="new-club-page-container"
    >
      <CssBaseline />
      <Popup isVisible={isPopupVisible} onClose={closePopup} content={popupContent} />
      <Box
        sx={{
          marginTop: 8,
          marginBottom: 4,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div className="title">הוסף מועדון חדש</div>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          alignContent="flex-start"
          columnGap="10px"
          width="100%"
          style={{ direction: "rtl", padding:"20px" }}
        >
          <div className="sub-title">Eventim</div>
          {eventimFields.map((field) => (
            <NewClubFormRow
              key={field.name}
              field={{ ...field }}
              isDirty={dirtyFields[field.name]}
              register={register}
              resetField={() => resetField(field.name)}
              error={errors[field.name]}
              setExcelData={setExcelData}
            />
          ))}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          alignContent="flex-start"
          columnGap="10px"
          width="100%"
          style={{ direction: "rtl", padding:"20px" }}
        >
          <div className="sub-title">Fortress</div>
          {fortressFields.map((field) => (
            <NewClubFormRow
              key={field.name}
              field={{...field, }}
							isDirty={dirtyFields[field.name]}
              register={register}
							resetField={() => resetField(field.name)}
              error={errors[field.name]}
              setExcelData={setExcelData}
            />
          ))}
        </Box>

        <Box
          display="flex"
          flexDirection="row-reverse"
          alignItems="flex-end"
          gap="100px"
          padding="20px"
        >
          <Button
            variant="contained"
            onClick={(e) => reset(defaultFieldValues)}
            style={{backgroundColor:"red"}}
          >
            התחל מחדש
          </Button>

          <Button variant="contained" type="submit">
            הוסף מועדון
          </Button>
        </Box>
      </form>

      <CssBaseline />
    </Container>
  );
};

export default NewClubComponent;
