import React, { ChangeEvent } from "react";
import { useState } from "react";
import Header from "../../components/Header/Header";
import rootStores from "../../stores";
import DataTable from "../../components/table/Table";
import { columns } from "./consts";
import { Box, Button } from "@mui/material";
import { renewReprint, renewSeasonAssessment } from "../../fetchers/RFIDTickets";
import { FileOpen } from "@mui/icons-material";
import * as XLSX from 'xlsx';

type Cell = string | number | null;

const RFIDTicketsStore = rootStores.RFIDTicketsStore;

const RenewSeasonComponent = () => {
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<any[][]>([]);

  const [renewRowsData, setRenewRowsData] = useState<[]>([]);
  const [renewPageSize, setRenewPageSize] = React.useState<number>(100);
  const [renewTotalRecords, setRenewTotalRecords] = useState<number>(0);

  const [reprintRowsData, setReprintRowsData] = useState<[]>([]);
  const [reprintPageSize, setReprintPageSize] = React.useState<number>(100);
  const [reprintTotalRecords, setReprintTotalRecords] = useState<number>(0);

  const [reprintArray, setReprintArray] = useState<[]>([]);
  const [responseRenewArray, setResponseRenewArray] = useState<[]>([]);

  const handleRenewSeasonAssessment = async () => {
    setIsTableLoading(true);

    console.log("excelData", excelData);
    const res = await renewSeasonAssessment(RFIDTicketsStore.club, excelData);
    setReprintArray(res.reprintArray);
    setResponseRenewArray(res.responseRenewArray);

    const renewResponse = res.responseRenewArray.map((renew: any, index: any) => {
      const { renewMember, response } = renew;
      return {
        id : index,
        RealMemberID: renewMember.RealMemberID,
        memberID: response.memberID,
        AreaCode: renewMember.AreaCode,
        OldAreaCode: renewMember.OldAreaCode,
        OldRow: renewMember.OldRow,
        OldSeat: renewMember.OldSeat,
        OldStadiumCode: renewMember.OldStadiumCode,
        OldStandCode: renewMember.OldStandCode,
        PriceBandCode: renewMember.PriceBandCode,
        OldPriceBandCode: renewMember.OldPriceBandCode,
        Row: renewMember.Row,
        Seat: renewMember.Seat,
        StandCode: renewMember.StandCode,
        cardNumber: response.cardNumber,
        errorCode: response.errorCode,
        errorDesc: response.errorDesc,
        errorNumber: response.errorNumber,
        foreName: renewMember.Forename,
        sureName: renewMember.Surname,
        emailAddress: renewMember.EmailAddress,
      }
    });
    setRenewRowsData(renewResponse);
    setRenewTotalRecords(renewResponse.length);

    const reprintResponse = res.reprintArray.map((reprint: any, index: any) => {
      return {
        id : index,
        RealMemberID: reprint.RealMemberID,
        memberID: reprint.memberID,
        AreaCode: reprint.AreaCode,
        OldAreaCode: reprint.OldAreaCode,
        OldRow: reprint.OldRow,
        OldSeat: reprint.OldSeat,
        OldStadiumCode: reprint.OldStadiumCode,
        OldStandCode: reprint.OldStandCode,
        PriceBandCode: reprint.PriceBandCode,
        OldPriceBandCode: reprint.OldPriceBandCode,
        Row: reprint.Row,
        Seat: reprint.Seat,
        StandCode: reprint.StandCode,
        foreName: reprint.Forename,
        sureName: reprint.Surname,
        emailAddress: reprint.EmailAddress,
      }
    });
    setReprintRowsData(reprintResponse);
    setReprintTotalRecords(reprintResponse.length);
    // setPageSize(100);

    setIsTableLoading(false);
  }

  const handleRenewReprintSeason = async () => {
    const res = await renewReprint(RFIDTicketsStore.club, reprintArray, responseRenewArray);
  }


  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const file = fileList[0];
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const result = e.target?.result;
        if (result && typeof result !== 'string') {
          const data = new Uint8Array(result);
          const workbook = XLSX.read(data, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          let excelData: Cell[][] = XLSX.utils.sheet_to_json(sheet, { header: 1 });
          excelData = excelData.filter((row: Cell[]) => row.some((cell: Cell | null) => cell !== null && cell !== ''));
          setExcelData(excelData);
        }
      };
      fileReader.readAsArrayBuffer(file);
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse"}}>
			<div style={{ margin: "30px 50px 0 50px", display: "flex", flexDirection: "column", flexGrow: 1}}>
        <Header
          handleFilter={() => {}}
          isRightDialogOpen={true}
				/>

        <Box display={"flex"} flexDirection={"row-reverse"} width={"100%"} gap={"10px"}>
          <Button
            component="label"
            role={undefined}
            tabIndex={-1}
            variant="outlined"
          >
            העלה קובץ

            <FileOpen style={{ marginRight: "10px" }} />

            <input
              type="file"
              // {...register(field.name, { required: true })}
              className="invisible-file-input"
              accept={"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, " + "application/vnd.ms-excel"}
              onChange={handleFileChange}
            />
          </Button>

          <Button
            variant="outlined"
            onClick={(e) => handleRenewSeasonAssessment()}
          >
            לחץ להערכה
          </Button>

          <Button
            variant="outlined"
            onClick={(e) => handleRenewReprintSeason()}
            disabled={renewRowsData.length <= 0}
          >
            לחץ לאישור
          </Button>
        </Box>

        <h2>renew</h2>
        <DataTable
          rows={renewRowsData}
          columns={columns}
          // handleSortModelChange={handleSortModelChange}
          isLoading={isTableLoading}
          withCheckbox={false}
          withAllCheckbox={false}
          // PAGINATION DATA // 
          pageSize={renewPageSize}
          setPageSize={setRenewPageSize}
          // page={filter.page}
          // setPage={(val) => setFilterField("page", val)}
          totalRecordsInDB={renewTotalRecords}
          />

        <h2>reprint</h2>
        <DataTable
          rows={reprintRowsData}
          columns={columns}
          // handleSortModelChange={handleSortModelChange}
          isLoading={isTableLoading}
          withCheckbox={false}
          withAllCheckbox={false}
          // PAGINATION DATA // 
          pageSize={reprintPageSize}
          setPageSize={setReprintPageSize}
          // page={filter.page}
          // setPage={(val) => setFilterField("page", val)}
          totalRecordsInDB={reprintTotalRecords}
          />
			</div>
    </div>
  );
};

export default RenewSeasonComponent;