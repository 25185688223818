import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  CssBaseline,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react";
import { Controller } from "react-hook-form";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

export interface IProps {
  type: string;
  labelText?: string;
  registerName?: any;
  style?: any;
  isFullWidth?: boolean;
  control?: any;
  helperTextError?: any;
  isRequired: boolean;
  pattern?: any;
  maxLength?: number;
  minLength?: number;
  isPassword?: boolean;
}

const rtlTheme = createTheme({ direction: "rtl" });

const TextComponent: React.FC<IProps> = ({
  type,
  labelText,
  registerName,
  style,
  isFullWidth,
  control,
  helperTextError,
  isRequired,
  pattern,
  maxLength,
  minLength,
  isPassword,
}) => {
  const [inputType, setInputType] = useState<string>(type);

  return (
    <Box sx={{ ...style }}>
      <ThemeProvider theme={rtlTheme}>
        <CssBaseline />

        <Controller
          name={registerName}
          control={control}
          defaultValue=""
          rules={{
            required: isRequired,
            pattern: pattern,
            maxLength: maxLength,
            minLength: minLength,
          }}
          render={({ field, fieldState }) => {
            return (
              <Box>
                <TextField
                  type={`${inputType ? inputType : "text"}`}
                  value={field.value ? field.value : ""}
                  label={<Typography>{`${labelText}`}</Typography>}
                  error={!!fieldState.error}
                  sx={{ direction: "rtl" }}
                  fullWidth={isFullWidth}
                  helperText={!!fieldState.error && helperTextError}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  inputRef={field.ref}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: isPassword && (
                      <IconButton
                        aria-label="toggle password visibility"
                        disabled={!field.value}
                        edge="end"
                        onClick={() => {
                          inputType === "password"
                            ? setInputType("text")
                            : setInputType("password");
                        }}
                      >
                        {inputType === "text" ? (
                          <VisibilityOffIcon />
                        ) : (
                          <VisibilityIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Box>
            );
          }}
        />
      </ThemeProvider>
    </Box>
  );
};

const TextComponentObserver = observer(TextComponent);
export default TextComponentObserver;
