import { action, makeAutoObservable, observable } from "mobx";
import { RootStore } from ".";

interface IMenuItems {
    path: string;
    label:string;
    name:string;
    hasSubMenu:boolean;
    menuKey:number;
    subMenuKey?:number;
    isSubMenu?:boolean;
  }

class NavBarStore {
  private _rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this._rootStore = rootStore;
    makeAutoObservable(this);
  }

  @observable
  selectedTab: IMenuItems={
    name:"",
    path:"",
    label:"ניהול הדפסות",
    hasSubMenu:false,
    menuKey:0,
    subMenuKey:0,
    isSubMenu:false

  };

  @action
  setSelectedTab = (obj:IMenuItems) => {
    this.selectedTab = obj;
  };
}

export default NavBarStore;
