import React, { useState } from "react";
import {
  Avatar,
  CssBaseline,
  Box,
  Typography,
  Container,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import TextComponent from "../../components/text/Text";
import { errorGenerator } from "../../utils/utils";
import { ILogin } from "../../interfaces/ILogin";
import rootStores from "../../stores/index";
import { useNavigate } from "react-router-dom";

const Copyright = (props: any) => {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      Ls-techs-
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const authStore = rootStores.AuthStore;

const LoginComponent = observer(() => {
  const { login } = authStore;
  const { handleSubmit, control, watch } = useForm();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

  const onSubmit: any = async ({ email, password }: ILogin) => {
    
		setLoading(true);
    
		const res = await login({ email, password });
    
		setLoading(false);

    if (!(res?.status === 200)) {
      if (res?.includes("401")) {
        setError("401Login");
      }
    } else {
			navigate("/");
		}
		
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          ממשק אדמין
        </Typography>

        <Box sx={{ mt: 3, minWidth: 300 }}>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <TextComponent
              control={control}
              registerName="email"
              labelText="שם משתמש"
              isRequired={true}
              type="email"
              isFullWidth={true}
              helperTextError="יש לכתוב כתובת מייל תקינה"
              pattern={
                /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+\.)+[A-Za-z]{2,4}$/g
              }
              style={{ marginTop: "5%" }}
            />
            <TextComponent
              isPassword={true}
              control={control}
              registerName="password"
              labelText="סיסמא"
              isRequired={true}
              type="password"
              isFullWidth={true}
              helperTextError="יש להזין סיסמא"
              style={{ marginTop: "5%" }}
            />

            {error && (
              <Alert
                severity="error"
                sx={{ direction: "rtl", minWidth: "100%", marginTop: "5%" }}
              >
                {errorGenerator(error)}
              </Alert>
            )}

            <LoadingButton
              type="submit"
              fullWidth
              loading={loading}
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={!watch("email") || !watch("password")}
            >
              <span>כניסה למערכת</span>
            </LoadingButton>
          </form>
        </Box>
      </Box>
      <Copyright sx={{ mt: 8, mb: 4 }} />
    </Container>
  );
});

export default LoginComponent;
