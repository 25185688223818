export const removeNulls1 = (obj: any) =>
    Object.entries(obj).reduce((acc: any, [key, value]) => {
    if (value !== null) {
      acc[key] = value;
    }
    return acc;
}, {});

export function removeNulls(obj: any) {
  for (let key in obj) {
    if (obj[key] === null || obj[key] === '') {
      delete obj[key];
    }
  }
  return obj;
}