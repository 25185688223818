export const errorGenerator = (status: string) => {
  switch (status) {
    case "401Login":
      return "אחד מהפרטים שהזנת שגויים";
    case "404Login":
      return "משתמש לא קיים";
    case "401BoUsers":
      return "משתמש זה קיים במערכת";
    case "404BoUsers":
      return "משתמש לא קיים";
    case "500BoUsers":
      return "משתמש זה קיים במערכת";
    default:
      return "אופס... משהו השתבש אנא נסה/י שוב מאוחר יותר";
  }
};
