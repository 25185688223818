import React from "react";
import { useState } from "react";
import Header from "../../components/Header/Header";
import { getTicketsCompareData } from "../../fetchers/RFIDTickets";
import rootStores from "../../stores";
import DataTable from "../../components/table/Table";
import { columns } from "./consts";

const RFIDTicketsStore = rootStores.RFIDTicketsStore;

const TicketsCompareComponent = () => {
  const [rowsData, setRowsData] = useState<[]>([]);
  const [pageSize, setPageSize] = React.useState<number>(100);
  const [isTableLoading, setIsTableLoading] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [evaXMLTicketsLength, setEvaXMLTicketsLength] = useState<number | null>(null);
  const [fortressTicketsLength, setFortressTicketsLength] = useState<number | null>(null);

  const handleFilter = async () => {
    if(RFIDTicketsStore.filter.event){
      setIsTableLoading(true);
      const res = await getTicketsCompareData(RFIDTicketsStore.club, RFIDTicketsStore.filter.event);
      const differentObjects = res?.differentObjects.map((obj: any, index: any) => ({ ...obj, id: index }));
      setRowsData(differentObjects);
      setEvaXMLTicketsLength(res.evaXMLTicketsLength);
      setFortressTicketsLength(res.fortressTicketsLength);
      setTotalRecords(differentObjects.length);
      setPageSize(differentObjects.length + 1);
      setIsTableLoading(false);
      console.log("getTicketsCompareData res", res);
    }else{
      alert("You must choose event");
    }
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "row-reverse"}}>
			<div style={{ margin: "30px 50px 0 50px", display: "flex", flexDirection: "column", flexGrow: 1}}>
        <Header
          handleFilter={handleFilter}
          isRightDialogOpen={true}
          filterFields={["event"]}
					/>
        <div style={{ display: "flex", justifyContent: "end", width: "100%"}}>
          <div style={{ display: "flex", justifyContent: "space-between", margin: "2vh"}}>
            {evaXMLTicketsLength && 
              <div style={{  margin: "0 4vh" }} >evaXML Tickets Length: {evaXMLTicketsLength} </div>
            }
            {fortressTicketsLength && 
              <div style={{  margin: "0 4vh" }}>fortress Tickets Length: {fortressTicketsLength} </div>
            }
          </div>
        </div>
        <DataTable
          rows={rowsData}
          columns={columns}
          // handleSortModelChange={handleSortModelChange}
          isLoading={isTableLoading}
          withCheckbox={false}
          withAllCheckbox={false}
          // PAGINATION DATA // 
          pageSize={pageSize}
          setPageSize={setPageSize}
          // page={filter.page}
          // setPage={(val) => setFilterField("page", val)}
          totalRecordsInDB={totalRecords}
          />
			</div>
    </div>
  );
};

export default TicketsCompareComponent;